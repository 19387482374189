// inc
@import "inc/inc";

/* table */
@import "sx/table";

/* form */
@import "sx/form";

/* slider */
@import "sx/slider";

/* mobile subnavi */
@import "sx/mobile_subnavi";

/* responsive collapse */
@import "sx/responsive_collapse";

header {
    padding: 35px 0 25px;

    .mobile-only {
        display: none;
    }
    .img {

        img {
            max-width: 100%;
            @include media-breakpoint-up('lg') {
                max-width: 400px;
            }
        }
    }
    .phone {
        img {
            float: right;
            max-height: 30px;
        }
        a {
            font-size: 25px;
            float: right;
            margin: -2px 0 0 15px;
        }
        @include media-breakpoint-down('lg') {
            a {
                font-size: 25px;
                margin-top: -2px;
            }
            img {
                max-height: 30px;
            }
        }
        @include media-breakpoint-down('md') {
            a {
                font-size: 28px;
                margin-top: -8px;
            }
            img {
                max-height: 28px;
            }
        }
        @media only screen and (max-width: 410px) {
            a {
                font-size: 24px;
                margin-top: -5px;
                margin-left: 10px;
            }
            img {
                max-height: 24px;
            }
        }
    }
    @include media-breakpoint-down('sm') {
        .desktop-only {
            display: none;
        }
        .mobile-only {
            display: block;
        }

        .phone {
            margin-top: 10px;
            img {
                float: left;
            }
            a{
                float: left;
            }
        }
    }
}

.content-container {
    .bh span {
        color: $brand-primary;
    }

    h1 {
        margin-top: 30px;
        font-size: 45px;
    }
    h2 {
        margin: 20px 0;
        font-size: 30px;
    }
    h3 {
        font-size: 20px;
    }

    p {
        font-size: 18px;
    }

    p.big {
        font-size: 18px;
        line-height: 1.4;
    }

    ul.feature-list {
        margin-top: 20px;
        li {
            font-size: 20px;
        }
    }

    p.small {
        font-size: 15px;
    }

    @include media-breakpoint-down('md') {
        h1 {
            font-size: 35px;
        }
        h2 {
            font-size: 25px;
        }
        h3 {
            font-size: 18px;
        }

        p {
            font-size: 15px;
        }

        p.big {
            font-size: 15px;
            line-height: 1.4;
        }

        ul.feature-list {
            li {
                font-size: 17px;
            }
        }

        button, a.btn-default {
            font-size: 18px;
        }
        p.small,
        p.footnote {
            font-size: 13px;
        }
    }

    p.footnote {
        font-size: 15px;
        margin: 20px 0 0;
    }

    .thin,
    .thin-container * {
        font-weight: $font-thin;
    }

    .padding-50-0 {
        padding: 50px 0;
        box-sizing: border-box;
    }

    .padding-30-0-40 {
        padding: 30px 0 40px;
        box-sizing: border-box;
    }

    .padding-20-0-30 {
        padding: 20px 0 30px;
        box-sizing: border-box;
    }

    .padding-20-0-0 {
        padding: 20px 0 0;
        box-sizing: border-box;
    }

    .padding-0-30 {
        padding: 0 0 30px;
        box-sizing: border-box;
    }

    .bg-rose {
        background: #fdf1e5;
    }
}


.treatment {
    margin-top: 40px;
    .treatment-highlight {
        margin-bottom: 10px;
        img {
            margin: 10px 0 15px;
            max-width: 100%;
            width: 100%;
        }
    }
}

.clients {

    .client {
        background: $brand-primary;
        height: 290px;

        .img {
            float: left;
            margin: 0;
            padding: 0;
            width: 49%;
            img {
                margin-top: -17px;
            }
        }
        .text {
            float: right;
            margin: 0;
            width: 49%;
            padding: 25px 25px 25px 0;
            color: #ffffff;
        }
        @include media-breakpoint-down('lg')
        {
            height: 100%;

            .text {
                float: none;
                width: 100%;
                padding: 25px;
            }

            .img {
                display: none;
            }
        }
    }
    @include media-breakpoint-down('sm'){
        .client {
            margin-bottom: 10px;
            height: auto;
        }
        > .col-12:last-of-type .client {
            margin-bottom: 0;
        }
    }
}

.team {
    margin-top: 30px;

    .team-member {
        position: relative;
        margin-bottom: 30px;

        img {
            display: block;
            padding-top: 15px;
        }
        .text {
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            height: auto;
            background: $brand-primary;
            padding: 25px 25px 15px 25px;
            box-sizing: border-box;
            color: #ffffff;
        }
    }
}

.phone-interuptor {
    img {
        float: left;
        margin-right: 15px;
        max-height: 30px;
    }
    a {
        float: left;
        font-size: 25px;
        margin-top: -3px;
    }
}

// container fix for emo-group
.almost-container {
    max-width: ( map_get($container-max-widths, 'xl') / 2 ) - 15px;
    @include media-breakpoint-down('lg') {
        max-width: ( map_get($container-max-widths, 'lg') / 2 ) - 15px;
    }
    @include media-breakpoint-down('md') {
        max-width: ( map_get($container-max-widths, 'md') / 2 ) - 15px;
    }
    @include media-breakpoint-down('sm') {
        max-width: 100%;
        margin: 0 auto;

        h1 {
            margin-top: 10px;
        }
    }
}

// Mobile Container fix for emo-group
@include media-breakpoint-down('sm') {
    .mobile-container {
        width: map_get($container-max-widths, 'sm');
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
    }
}

