// inc
@import "../inc/inc";

.responsive-toggle-indicator {
    display: inline-block;
    &:before {
        content: '\f068';
        font-size: 0.85em;
        font-family: 'FontAwesome';
        display: inline-block;

        .collapsed & {
            content: '\f067';
        }
    }
}
