// inc
@import "../inc/inc";

.owl-carousel {
    .owl-dots {
        .owl-dot {
            display: inline-block;

            & + .owl-dot {
                margin-left: 0.4rem;
            }

            span {
                display: inline-block;
                background-color: $black;
                opacity: 0.5;
                width: 5px;
                height: 5px;
                transition: opacity 0.25s ease-in-out;

                @if $enable-rounded {
                    border-radius: 50%;
                }
            }

            &.active {
                span {
                    opacity: 1;
                }
            }
        }
    }
}
