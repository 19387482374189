// inc
@import "../inc/inc";

[data-mobile-dropdown-nav] {
    .active-item {
        display: none;
    }

    &.mobile-dropdown-nav-enabled {
        background-color: $brand-primary;
        color: #FFFFFF;
        padding: 0.7rem 1rem;
        position: relative;
        margin-bottom: 1rem;

        a,
        .nav-link {
            color: inherit;

            &:hover {
                color: #CCCCCC;
            }
        }

        .nav-item {
            &.active {
                > a,
                > .nav-link {
                    color: #CCCCCC !important;
                }
            }
        }

        .active-item {
            display: block;
        }

        > .nav {
            display: none;
            border-top: 1px solid #FFFFFF;
            padding-top: 0.5rem;
            position: absolute;
            top: 100%;
            background-color: $brand-primary;
            left: 0;
            right: 0;
            z-index: $zindex-dropdown;
            padding: 0.7rem 1rem;
            box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
        }

        &.open {
            .nav {
                display: block;
            }
        }

        [data-mobile-dropdown-nav-toggle] {
            a {
                &:after {
                    content: '\f078';
                    font-family: 'FontAwesome';
                    float: right;
                }
            }
        }

    }

}
