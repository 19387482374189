/* table */
/* form */
.form-group .col-form-label {
  font-weight: bold;
}

/* slider */
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel .owl-dots .owl-dot + .owl-dot {
  margin-left: 0.4rem;
}

.owl-carousel .owl-dots .owl-dot span {
  display: inline-block;
  background-color: #000;
  opacity: 0.5;
  width: 5px;
  height: 5px;
  transition: opacity 0.25s ease-in-out;
}

.owl-carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}

/* mobile subnavi */
[data-mobile-dropdown-nav] .active-item {
  display: none;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled {
  background-color: #ee7100;
  color: #FFFFFF;
  padding: 0.7rem 1rem;
  position: relative;
  margin-bottom: 1rem;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled a,
[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled .nav-link {
  color: inherit;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled a:hover,
[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled .nav-link:hover {
  color: #CCCCCC;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled .nav-item.active > a,
[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled .nav-item.active > .nav-link {
  color: #CCCCCC !important;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled .active-item {
  display: block;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled > .nav {
  display: none;
  border-top: 1px solid #FFFFFF;
  padding-top: 0.5rem;
  position: absolute;
  top: 100%;
  background-color: #ee7100;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0.7rem 1rem;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled.open .nav {
  display: block;
}

[data-mobile-dropdown-nav].mobile-dropdown-nav-enabled [data-mobile-dropdown-nav-toggle] a:after {
  content: '\f078';
  font-family: 'FontAwesome';
  float: right;
}

/* responsive collapse */
.responsive-toggle-indicator {
  display: inline-block;
}

.responsive-toggle-indicator:before {
  content: '\f068';
  font-size: 0.85em;
  font-family: 'FontAwesome';
  display: inline-block;
}

.collapsed .responsive-toggle-indicator:before {
  content: '\f067';
}

header {
  padding: 35px 0 25px;
}

header .mobile-only {
  display: none;
}

header .img img {
  max-width: 100%;
}

@media (min-width: 1024px) {
  header .img img {
    max-width: 400px;
  }
}

header .phone img {
  float: right;
  max-height: 30px;
}

header .phone a {
  font-size: 25px;
  float: right;
  margin: -2px 0 0 15px;
}

@media (max-width: 1249px) {
  header .phone a {
    font-size: 25px;
    margin-top: -2px;
  }
  header .phone img {
    max-height: 30px;
  }
}

@media (max-width: 1023px) {
  header .phone a {
    font-size: 28px;
    margin-top: -8px;
  }
  header .phone img {
    max-height: 28px;
  }
}

@media only screen and (max-width: 410px) {
  header .phone a {
    font-size: 24px;
    margin-top: -5px;
    margin-left: 10px;
  }
  header .phone img {
    max-height: 24px;
  }
}

@media (max-width: 767px) {
  header .desktop-only {
    display: none;
  }
  header .mobile-only {
    display: block;
  }
  header .phone {
    margin-top: 10px;
  }
  header .phone img {
    float: left;
  }
  header .phone a {
    float: left;
  }
}

.content-container .bh span {
  color: #ee7100;
}

.content-container h1 {
  margin-top: 30px;
  font-size: 45px;
}

.content-container h2 {
  margin: 20px 0;
  font-size: 30px;
}

.content-container h3 {
  font-size: 20px;
}

.content-container p {
  font-size: 18px;
}

.content-container p.big {
  font-size: 18px;
  line-height: 1.4;
}

.content-container ul.feature-list {
  margin-top: 20px;
}

.content-container ul.feature-list li {
  font-size: 20px;
}

.content-container p.small {
  font-size: 15px;
}

@media (max-width: 1023px) {
  .content-container h1 {
    font-size: 35px;
  }
  .content-container h2 {
    font-size: 25px;
  }
  .content-container h3 {
    font-size: 18px;
  }
  .content-container p {
    font-size: 15px;
  }
  .content-container p.big {
    font-size: 15px;
    line-height: 1.4;
  }
  .content-container ul.feature-list li {
    font-size: 17px;
  }
  .content-container button, .content-container a.btn-default {
    font-size: 18px;
  }
  .content-container p.small,
  .content-container p.footnote {
    font-size: 13px;
  }
}

.content-container p.footnote {
  font-size: 15px;
  margin: 20px 0 0;
}

.content-container .thin,
.content-container .thin-container * {
  font-weight: 200;
}

.content-container .padding-50-0 {
  padding: 50px 0;
  box-sizing: border-box;
}

.content-container .padding-30-0-40 {
  padding: 30px 0 40px;
  box-sizing: border-box;
}

.content-container .padding-20-0-30 {
  padding: 20px 0 30px;
  box-sizing: border-box;
}

.content-container .padding-20-0-0 {
  padding: 20px 0 0;
  box-sizing: border-box;
}

.content-container .padding-0-30 {
  padding: 0 0 30px;
  box-sizing: border-box;
}

.content-container .bg-rose {
  background: #fdf1e5;
}

.treatment {
  margin-top: 40px;
}

.treatment .treatment-highlight {
  margin-bottom: 10px;
}

.treatment .treatment-highlight img {
  margin: 10px 0 15px;
  max-width: 100%;
  width: 100%;
}

.clients .client {
  background: #ee7100;
  height: 290px;
}

.clients .client .img {
  float: left;
  margin: 0;
  padding: 0;
  width: 49%;
}

.clients .client .img img {
  margin-top: -17px;
}

.clients .client .text {
  float: right;
  margin: 0;
  width: 49%;
  padding: 25px 25px 25px 0;
  color: #ffffff;
}

@media (max-width: 1249px) {
  .clients .client {
    height: 100%;
  }
  .clients .client .text {
    float: none;
    width: 100%;
    padding: 25px;
  }
  .clients .client .img {
    display: none;
  }
}

@media (max-width: 767px) {
  .clients .client {
    margin-bottom: 10px;
    height: auto;
  }
  .clients > .col-12:last-of-type .client {
    margin-bottom: 0;
  }
}

.team {
  margin-top: 30px;
}

.team .team-member {
  position: relative;
  margin-bottom: 30px;
}

.team .team-member img {
  display: block;
  padding-top: 15px;
}

.team .team-member .text {
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: auto;
  background: #ee7100;
  padding: 25px 25px 15px 25px;
  box-sizing: border-box;
  color: #ffffff;
}

.phone-interuptor img {
  float: left;
  margin-right: 15px;
  max-height: 30px;
}

.phone-interuptor a {
  float: left;
  font-size: 25px;
  margin-top: -3px;
}

.almost-container {
  max-width: 585px;
}

@media (max-width: 1249px) {
  .almost-container {
    max-width: 475px;
  }
}

@media (max-width: 1023px) {
  .almost-container {
    max-width: 355px;
  }
}

@media (max-width: 767px) {
  .almost-container {
    max-width: 100%;
    margin: 0 auto;
  }
  .almost-container h1 {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .mobile-container {
    width: 540px;
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
}
